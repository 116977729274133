@import "~@/assets/scss/global.scss";


































































































































































































































































































































































































































































#wrapper {
  height: 100%;
  width: 100%;

  ::v-deep {

    .el-input__inner,
    .el-button {
      height: 40px !important;
      width: 100%;
      font-size: 14px;
    }

    .el-input__icon {
      font-size: 18px;
      display: inline-block;
    }

    .el-input__suffix {
      line-height: 40px;
      cursor: pointer;

      i {
        font-size: 18px;
        opacity: 0.8;
        cursor: pointer;
      }
    }

    .el-dialog__body {
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      line-height: 22px;
    }

    .el-dialog__footer {
      text-align: center;

      .el-button {
        width: 300px;
      }
    }

    .el-link.el-link--default {
      color: white !important;
    }
  }

  .customer-service {
    img {
      width: 300px;
      height: 300px;
      border: 3px solid white;
    }

    ::v-deep {
      .el-dialog {
        background: none;
        box-shadow: none;

        .el-dialog__header {
          .el-icon-close::before {
            color: white;
            font-size: 30px;
            font-weight: bold;
            border: 2px solid white;
            border-radius: 30px;
          }
        }

        .el-dialog__body {
          border-top: none;
        }
      }
    }
  }
}

#header {
  height: 13%;
  min-height: 85px;
  position: relative;

  #logo {

    height: 70px;
    position: absolute;
    bottom: 0;
    left: 50px;
  }
}

#main-wrapper {
  height: 77%;
  min-height: 540px;
  position: relative;

  #main {
    width: 1200px;
    height: 440px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -220px;
    margin-left: -600px;

    .login-left {
      float: left;
      width: 806px;
      height: 440px;
    }

    .login-right {
      float: left;
      height: 440px;
      width: 394px;
      background: white;
      position: relative;

      .loginActive {
        color: #0075e3;
        position: relative;

        &::after {
          content: '';
          height: 1px;
          width: 64px;
          border-bottom: 2px solid #0075e3;
          position: absolute;
          top: 22px;
          left: 0;
        }
      }

      .row-block {
        margin-bottom: 18px;
        width: 100%;

        &.end-block {
          text-align: right;
          margin-top: 28px;

          span {
            margin-left: 15px;
            cursor: pointer;
          }
        }
      }

      .register-block {
        .row-block {
          margin-bottom: 12px;
        }

        .end-block {
          margin-top: 0;
        }
      }

      .register-error {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 12px;
        font-weight: 400;
        color: #ff0000;

        i {
          font-size: 14px;
          font-weight: 600;
          margin-right: 3px;
        }
      }

      .error-block {
        border: 1px solid #ff6185;
        background: #ffecf0;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        color: #f52554;
        padding: 0 5px;
        box-sizing: border-box;
        border-radius: 4px;

        i {
          font-size: 16px;
        }
      }
    }
  }
}

#footer-wrapper {
  height: 10%;
  min-height: 40px;
  position: relative;

  #footer {
    height: 40px;
    width: 100%;
    position: absolute;
    bottom: 0;
    color: white;
    line-height: 40px;
    text-align: center;
    background: rgba(0, 0, 0, 0.08);

    a {
      color: white;

      img {
        vertical-align: middle;
        height: 20px;
        width: 20px;
      }
    }
  }
}
